import axios from 'axios';
import autocomplete from 'autocomplete.js';
import ApplicationController from './application_controller';

export default class extends ApplicationController {
  static targets = [
    'selectInput',
    'cityInput',
    'terminalInput',
    'transporterInput',
    'stateInput',
    'lineInput',
    'routeInput',
    'inputContainer',
    'cityField',
    'terminalField',
    'originCityField',
    'destinationCityField',
    'cityHideInput',
    'terminalHideInput',
    'originCityHideInput',
    'destinationCityHideInput',
    'destinationInputTarget',
    'discountContainer',
    'discountableData',
  ];

  connect() {
    this.ac = {};
    this.selectTemplate(this.selectInputTarget.value);

    if (this.discountType == 'City') {
      this.setSlug(this.cityHideInputTarget);
    } else if (this.discountType == 'Terminal') {
      this.setSlug(this.terminalHideInputTarget);
    } else if (this.discountType == 'Route') {
      this.setSlug(this.originCityHideInputTarget);
      this.setSlug(this.destinationCityHideInputTarget);
    }
  }

  setSlug(hideInput) {
    const discountable_data = JSON.parse(this.discountableData);
    if (discountable_data.type == 'Route') {
      hideInput.value =
        hideInput.id == 'discount_origin_city_name'
          ? discountable_data.value.origin_city_name
          : discountable_data.value.destination_city_name;
    } else {
      hideInput.value = discountable_data.value.slug;
    }
  }

  source(filterType) {
    if (filterType == 'route') {
      filterType = 'city';
    }
    const url = this.placesUrl;
    return (q, callback) => {
      axios.get(url, { params: { q } }).then((response) => {
        callback(response.data.filter(({ result_type }) => result_type == filterType));
      });
    };
  }

  discount_select(e) {
    const discountType = e.target.value;
    this.selectTemplate(discountType);
    
    // Auto-fill fields when WalletBalanceDiscount is selected
    if (discountType === 'WalletBalanceDiscount' || discountType === 'CancellationDiscount' ||
        discountType === 'TripIncidentDiscount' || discountType === 'AgentErrorDiscount') {
      // Generate random code (alphanumeric, 8 characters)
      const randomCode = Math.random().toString(36).substring(2, 10).toUpperCase();
      document.querySelector('input[name="discount[code]"]').value = randomCode;
      
      // Set max_use to 1
      document.querySelector('input[name="discount[max_use]"]').value = 1;
      
      // Set expires_at to the last day of the current year (December 31st)
      const today = new Date();
      const lastDayOfYear = new Date(today.getFullYear(), 11, 31); // Month is 0-indexed, so 11 is December
      
      const formattedDate = lastDayOfYear.toISOString().split('T')[0]; // Format as YYYY-MM-DD for the date field
      document.querySelector('input[name="discount[expires_at]"]').value = formattedDate;
      
      // Check the accumulable checkbox - using the exact ID selector
      const accumulableCheckbox = document.getElementById('discount_accumulable');
      if (accumulableCheckbox) {
        accumulableCheckbox.checked = true;
        // Trigger change event to ensure any listeners are notified
        accumulableCheckbox.dispatchEvent(new Event('change', { bubbles: true }));
      }
    }
  }

  selectPlace(type, inputTarget, hideTarget) {
    const currentType = type == 'route' ? 'city' : type;
    const dataTarget = inputTarget.getAttribute('data-discounts-target');
    this.ac[dataTarget] = autocomplete(inputTarget, { hint: false }, [
      {
        source: this.source(currentType),
        debounce: 200,
        templates: {
          suggestion: function (suggestion) {
            return `${suggestion.display}, ${
              currentType == 'city' ? suggestion.state : suggestion.city_name
            }`;
          },
        },
      },
    ]).on('autocomplete:selected', (event, suggestion, dataset, context) => {
      if (document.activeElement === event.target) {
        this.ac[dataTarget].autocomplete.setVal(suggestion.display);
        hideTarget.value = type == 'route' ? suggestion.display : suggestion.slug;
      }
    });
  }

  insertPlaceInputs(fieldTarget, hidedTarget, currentDiscountType) {
    this.selectPlace(currentDiscountType.toLowerCase(), fieldTarget, hidedTarget);

    if (this.discountType != currentDiscountType) {
      fieldTarget.value = '';
    }
  }

  selectTemplate(type) {
    switch (type) {
      case 'City':
        this.insertTemplate(this.cityInputTarget, this.inputContainerTarget);
        this.insertPlaceInputs(this.cityFieldTarget, this.cityHideInputTarget, type);
        break;
      case 'Terminal':
        this.insertTemplate(this.terminalInputTarget, this.inputContainerTarget);
        this.insertPlaceInputs(this.terminalFieldTarget, this.terminalHideInputTarget, type);
        break;
      case 'Transporter':
        this.insertTemplate(this.transporterInputTarget, this.inputContainerTarget);
        break;
      case 'Route':
        this.insertTemplate(this.routeInputTarget, this.inputContainerTarget);

        this.insertPlaceInputs(this.originCityFieldTarget, this.originCityHideInputTarget, type);
        this.insertPlaceInputs(
          this.destinationCityFieldTarget,
          this.destinationCityHideInputTarget,
          type
        );
        break;
      case 'Line':
        this.insertTemplate(this.lineInputTarget, this.inputContainerTarget);
        break;
      case 'WalletBalanceDiscount':
        // No additional fields required for WalletBalanceDiscount type
        this.inputContainerTarget.innerHTML = '';
        break;
      default:
        this.inputContainerTarget.innerHTML = '';
        break;
    }
  }

  get discountType() {
    return this.data.get('type');
  }

  get discountableData() {
    return this.discountableDataTarget.innerHTML;
  }

  get placesUrl() {
    return this.data.get('placesUrl');
  }
}
